import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    getNewAccount(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/dashboard/newaccount', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getAllAccount(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/dashboard/totaluser', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getAllTransaction(ctx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/dashboard/totaltransaction', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
