import axios from '../../http/axios'

// const baseURL = 'http://localhost:3001'

export default {
  namespaced: true,
  state: {
    listAccount: [],
    count: 0,
  },
  mutations: {
    SET_LIST_ACCOUNT(state, item) {
      state.listAccount = item
    },
    SET_COUNT(state, total) {
      state.count = total
    },
  },
  actions: {
    getAllAccount({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/account/getaccounts')
          .then(response => {
            commit('SET_LIST_ACCOUNT', response.data.items)
            commit('SET_COUNT', response.data.count)
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getAllAccountFail({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/account/getaccountsfail')
          .then(response => {
            commit('SET_LIST_ACCOUNT', response.data.items)
            commit('SET_COUNT', response.data.count)
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getAccountbyId(qtx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/account/getaccountbyid', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    addAccount(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/register', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    editAccount(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/account/edit', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    removeAccount(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/account/remove', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    searchAccount(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/account/search', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    confirmAccount(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/account/admin/confirmaccount', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    cancelAccount(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/account/admin/cancelaccount', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
