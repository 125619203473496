import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
// import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn } from '@/auth/utils'
// import apps from './routes/apps'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
// import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'
import production from './routes/production'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'Dashboard' } },
    // ...apps,
    // ...dashboard,
    // ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    ...production,
    {
      path: '*',
      redirect: 'Dashboard',
    },
  ],
})

router.beforeEach((to, from, next) => {
  console.log(to)
  const isLoggedIn = !!localStorage.getItem('userData')
  if (to.meta.requiredAuth) {
    if (!isLoggedIn) {
      next({ name: 'Login' })
    }
    next()
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: 'Dashboard' })
  }

  next()
})

// // ? For splash screen
// // Remove afterEach hook if you are not using splash screen
// router.afterEach(() => {
//   // Remove initial loading
//   const appLoading = document.getElementById('loading-bg')
//   if (appLoading) {
//     appLoading.style.display = 'none'
//   }
// })

export default router
