import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
    listAgent: [],
    count: 0,
  },
  mutations: {
    SET_LIST_AGENT(state, item) {
      state.listAgent = item
    },
    SET_COUNT(state, total) {
      state.count = total
    },
  },
  actions: {
    getAllAgent({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/agent/getagents')
          .then(response => {
            commit('SET_LIST_AGENT', response.data.items)
            commit('SET_COUNT', response.data.count)
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getAgentbyid(qtx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/agent/getagentbyid', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    addAgent(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/agent/add', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    editAgent(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/agent/edit', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    removeAgent(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/agent/remove', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
