import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
    listWithdraw: [],
    count: 0,
  },
  mutations: {
    SET_LIST_CASH(state, item) {
      state.listWithdraw = item
    },
    SET_COUNT(state, total) {
      state.count = total
    },
  },
  actions: {
    getAllWithdraw({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/transaction/getwithdraw')
          .then(response => {
            commit('SET_LIST_CASH', response.data.items)
            commit('SET_COUNT', response.data.count)
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    withdrawCash(ctx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/transactions', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    withdrawCredit(ctx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/admin/withdraw', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
