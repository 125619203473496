import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Abbreviate from 'number-abbreviate'

import i18n from '@/libs/i18n'
import moment from 'moment'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(moment)

Vue.filter('toTimeThai', value => {
  const dataVal = value
  return moment(dataVal).locale('th').format('LLL')
})

Vue.filter('toPercen', value => {
  const price = parseFloat(value[0].price)
  const percen = parseFloat(value[0].percen)
  const result = price - ((price / 100) * percen)
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  return formatter.format(result)
})

Vue.filter('toCurrency', value => {
  let dataVal = value
  if (typeof dataVal !== 'number') {
    dataVal = parseFloat(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  return formatter.format(dataVal)
})
Vue.filter('toAmount', value => {
  let dataVal = value
  if (typeof dataVal !== 'number') {
    dataVal = parseFloat(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 0 })
  return formatter.format(dataVal)
})
Vue.filter('toCurrencyBath', value => {
  let dataVal = value
  if (typeof value !== 'number') {
    dataVal = parseFloat(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  let result
  if (value >= 1000000) {
    result = `${Abbreviate(dataVal, 2)} ฿`
  } else {
    result = `${formatter.format(dataVal)} ฿`
  }
  return result
})

Vue.filter('toCurrencyBathTable', value => {
  let dataVal = value
  if (typeof value !== 'number') {
    dataVal = parseFloat(dataVal)
  }
  const formatter = new Intl.NumberFormat('th', { minimumFractionDigits: 2 })
  return `${formatter.format(dataVal)} ฿`
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  // eslint-disable-next-line arrow-parens
  render: (h) => h(App),
}).$mount('#app')
