import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    getConfig() {
      return new Promise((resolve, reject) => {
        axios.get('/config/getall')
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    editRegisterAgent(rtx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/config/edit', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
