import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
// import lotteryModule from '@/views/lottery/lotteryModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import moduleWithdraw from './withdraw/moduleWithdraw'
import moduleDeposit from './deposit/moduleDeposit'
import moduleAccount from './account/moduleAccount'
import moduleAdmin from './admin/moduleAdmin'
import moduleAgent from './agent/moduleAgent'
import moduleTransaction from './transaction/moduleTransaction'
// import moduleCash from './cash/moduleCash'
import moduleAuth from './auth/moduleAuth'
import moduleDashboard from './dashboard'
import moduleConfig from './config/moduleConfig'
import moduleBank from './bank'
import moduleGame from './game'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    // withdraw: moduleWithdraw,
    account: moduleAccount,
    deposit: moduleDeposit,
    transaction: moduleTransaction,
    // cash: moduleCash,
    agent: moduleAgent,
    admin: moduleAdmin,
    auth: moduleAuth,
    dashboard: moduleDashboard,
    withdraw: moduleWithdraw,
    config: moduleConfig,
    bank: moduleBank,
    game: moduleGame,
  },
  strict: process.env.DEV,
})
