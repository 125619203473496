import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
  },
  mutations: {

  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    updateStatus({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/game/update', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
