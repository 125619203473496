import axios from '../../http/axios'

// const baseURL = 'http://localhost:3004'

export default {
  namespaced: true,
  state: {
    listAdmin: [],
    count: 0,
  },
  mutations: {
    SET_LIST_ADMIN(state, item) {
      state.listAdmin = item
    },
    SET_COUNT(state, total) {
      state.count = total
    },
  },
  actions: {
    getAllAdmin({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/admin/getadmins')
          .then(response => {
            commit('SET_LIST_ADMIN', response.data.items)
            commit('SET_COUNT', response.data.count)
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getAdminbyId(qtx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/admin/getadminbyid', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    addAdmin(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/admin/add', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    editAdmin(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/admin/edit', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    removeAdmin(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/admin/delete', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
