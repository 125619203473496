export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

export const initialAbility2 = [
  {
    action: 'manage',
    subject: 'all',
  },
]

export const _ = undefined
