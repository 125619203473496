// import axiosIns from '@/libs/axios'

import axios from '../../axios'

let subscribers = []

function refreshToken() {
  return axios.post('/auth/refreshtoken', { refreshToken: localStorage.refreshToken })
}

function setaccessToken(token) {
  localStorage.setItem('accessToken', token)
}

function setrefreshToken(token) {
  localStorage.setItem('refreshToken', token)
}

// function logOut() {
//   localStorage.removeItem('accessToken')
//   localStorage.removeItem('refreshToken')
//   localStorage.removeItem('userData')
// }

function onAccessTokenFetched(accessToken) {
  subscribers = subscribers.filter(callback => callback(accessToken))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

let isAlreadyFetchingAccessToken = false

axios.interceptors.request.use(config => {
  const token = localStorage.accessToken
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
},
error => Promise.reject(error))

axios.interceptors.response.use(res => res,
  async error => {
    const { config, response } = error
    const originalRequest = config

    // eslint-disable-next-line no-underscore-dangle
    if (response.status === 401) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        refreshToken().then(r => {
          isAlreadyFetchingAccessToken = false
          setaccessToken(r.data.accessToken)
          setrefreshToken(r.data.refreshToken)
          onAccessTokenFetched(r.data.accessToken)
        })
      }
      const retryOriginalRequest = new Promise(resolve => {
        addSubscriber(accessToken => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`
          // eslint-disable-next-line no-underscore-dangle
          originalRequest._retry = true
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
          resolve(axios(originalRequest))
        })
      })
      return retryOriginalRequest
    }
    return Promise.reject(error)
  })

export default axios
