import axios from '../../http/axios'

// const baseURL = 'http://localhost:3011'

export default {
  namespaced: true,
  state: {
    listBank: [],
    count: 0,
  },
  mutations: {
    SET_LIST_BANK(state, item) {
      state.listBank = item
    },
    SET_COUNT(state, total) {
      state.count = total
    },
  },
  actions: {
    getAllBank({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/bank/getbanks')
          .then(response => {
            commit('SET_LIST_BANK', response.data.items)
            commit('SET_COUNT', response.data.count)
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getBankbyId(qtx, item) {
      return new Promise((resolve, reject) => {
        axios.post('/bank/getbankbyid', item)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    addBank(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/bank/add', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    editBank(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/bank/edit', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    removeBank(qtx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/bank/delete', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
