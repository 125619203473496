import axios from '../../http/axios'

export default {
  namespaced: true,
  state: {
    auth_user: false,
  },
  mutations: {
    SET_BEARER(state, Token) {
      axios.defaults.headers.common.Authorization = `Bearer ${Token}`
    },
    SET_LOGIN(state, payload) {
      state.auth_user = payload
    },
  },
  actions: {
    validateLogin({ commit }, Account) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/admin/login', Account)
          .then(response => {
            if (response.data.success) {
              commit('SET_BEARER', response.data.accesstoken)
            }
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    refreshToken(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/refreshtoken', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
