export default [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/production/auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    meta: {
      requiredAuth: true,
    },
    component: () => import('@/views/production/account/Account.vue'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    meta: {
      requiredAuth: true,
    },
    component: () => import('@/views/production/dashboard/Dashboard.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    meta: {
      requiredAuth: true,
    },
    component: () => import('@/views/production/admin/Admin.vue'),
  },
  {
    path: '/agent',
    name: 'Agent',
    meta: {
      requiredAuth: true,
    },
    component: () => import('@/views/production/agent/Agent.vue'),
  },
  {
    path: '/transaction',
    name: 'Transaction',
    meta: {
      requiredAuth: true,
    },
    component: () => import('@/views/production/transaction/Transaction.vue'),
  },
  {
    path: '/bank',
    name: 'Bank',
    meta: {
      requiredAuth: true,
    },
    component: () => import('@/views/production/bank/Bank.vue'),
  },
  {
    path: '/setting',
    name: 'Setting',
    meta: {
      requiredAuth: true,
    },
    component: () => import('@/views/production/setting/Setting.vue'),
  },
]
