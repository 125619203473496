import axios from '../../http/axios'

// const baseURL = 'http://localhost:3001'

export default {
  namespaced: true,
  state: {
    listTransaction: [],
    listWaitingTransaction: [],
    countAll: 0,
    countWaiting: 0,
  },
  mutations: {
    SET_LIST_TRANSACTION(state, item) {
      state.listTransaction = item
    },
    SET_COUNT_ALL(state, total) {
      state.countAll = total
    },
    SET_LIST_WAITING_TRANSACTION(state, item) {
      state.listWaitingTransaction = item
    },
    SET_COUNT_WAITING(state, total) {
      state.countWaiting = total
    },
  },
  actions: {
    getAllTransaction({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/transaction/getalltransaction')
          .then(response => {
            commit('SET_LIST_TRANSACTION', response.data.items)
            commit('SET_COUNT_ALL', response.data.count)
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getWaitingTransaction({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/transaction/getwaitingtransaction')
          .then(response => {
            commit('SET_LIST_WAITING_TRANSACTION', response.data.items)
            commit('SET_COUNT_WAITING', response.data.count)
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    updateTransaction(ctx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/update', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    checkCredit(ctx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/admin/credit', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getSumCard(ctx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/getsumtransaction', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
    updateStatus(ctx, items) {
      return new Promise((resolve, reject) => {
        axios.post('/transaction/admin/updatestatus', items)
          .then(response => {
            resolve(response)
          }).catch(err => {
            reject(err)
          })
      })
    },
  },
}
